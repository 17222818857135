import * as React from 'react'
import PropTypes from 'prop-types'
import { MDContent } from '../components/Content'

const AboutCenterContent = ({ content, contentComponent }) => {
  const AboutContent = contentComponent ?? MDContent

  return (
    <div className="col-12 col-lg-6 col-xl-8 offset-lg-3 offset-xl-2 py-7 px-lg-7">
      <AboutContent content={content} />
    </div>
  )
}

AboutCenterContent.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
}

export default AboutCenterContent
