import * as React from 'react'
import PropTypes from 'prop-types'
import LanguageContext from '../context/LanguageContext'
import { Breadcrumb } from 'react-bootstrap'
import { useIntl } from 'react-intl'

const AboutBreadcrumbs = ({ toggleClick, page }) => {

  const { formatMessage: f } = useIntl()

  return (
    <LanguageContext.Consumer>{() => <>
      <nav className="bg-gray-800 g-0 d-lg-none">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col">
              <Breadcrumb>
                <Breadcrumb.Item>{f({ id: 'navbar_about' })}</Breadcrumb.Item>
                <Breadcrumb.Item>{page}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="col-auto">
              <div className="navbar-dark">
                <button className="navbar-toggler" type="button" aria-controls="sidenavCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={() => toggleClick()}>
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
    }
    </LanguageContext.Consumer>
  )
}

AboutBreadcrumbs.propTypes = {
  toggleClick: PropTypes.func,
  page: PropTypes.string,
}

export default AboutBreadcrumbs