import * as React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import LanguageContext from '../context/LanguageContext'
import Social from '../components/Social'
import TdLink from './TdLink'
import { Collapse } from 'react-bootstrap'
import SelectLanguage from './SelectLanguages'

const order = ['domains', 'auctions', 'publishing-websites', 'dns-bridge', 'faq', 'distribution-proceeds', 'keyholders', 'dao', 'constitution', 'foundation', 'pool', 'tos', 'privacy-policy'];
const orderMap = new Map(order.map((key, index) => [key, index]))

const LeftNavTemplate = ({ nodes, collapsed }) => {

  const buildLinksMap = (lang) => {
    const filteredNodes = nodes.filter(({ fields }) => fields.langKey === lang.langKey)
    const sortedNodes = filteredNodes.slice().sort((a, b) => orderMap.get(a.frontmatter.id) - orderMap.get(b.frontmatter.id))
    const sections = sortedNodes.reduce((map, node) => {
      const sectionName = node.frontmatter.section
      let section = map.get(sectionName)

      if (!section) {
        map.set(sectionName, [])
        section = map.get(sectionName)
      }

      section.push(node)

      return map
    }, new Map())

    return sections
  }

  const getLinks = (lang) => {
    const sections = buildLinksMap(lang)
    const sectionKeys = Array.from(sections.keys())

    return sectionKeys.map(key => (
      <React.Fragment key={key}>
        <h6 className="text-uppercase fw-bold">{key}</h6>
        <ul className="list mb-6">
          {sections.get(key).map(({ fields, frontmatter }) => (
            <li className="list-item" key={fields.slug}>
              <TdLink className="list-link" to={fields.slug}>
                {frontmatter.headline}
              </TdLink>
            </li>
          ))}
        </ul>
      </React.Fragment>
    ))
  }

  return (
    <LanguageContext.Consumer>{(value) =>
      <div className="col-12 col-lg-3 col-xl-2 px-lg-0 border-bottom border-bottom-lg-0 border-right-lg border-gray-300 sidenav sidenav-left pt-2">
        <Collapse in={collapsed}>
          <div className="d-lg-block">
            <div className="py-7 px-lg-7">
              {getLinks(value)}

              <div className="left-nav-language mb-6">
                <SelectLanguage />
              </div>

              <Social />
            </div>
          </div>
        </Collapse>
      </div>
    }
    </LanguageContext.Consumer>
  )
}

LeftNavTemplate.propTypes = {
  nodes: PropTypes.array,
  collapsed: PropTypes.bool
}

export default function AboutLeftNav({ collapsed }) {
  return (
    <StaticQuery
      query={graphql`
        query AllAboutPages {
          allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "about-page" } } }) {
            nodes {
              frontmatter {
                headline
                section
                id
              }
              fields {
                slug
                langKey
              }
            }
          }
        }
      `}
      render={data => {
        return <LeftNavTemplate nodes={data.allMarkdownRemark.nodes} collapsed={collapsed} />
      }}
    />
  )
}
